export const splitLines = el => {
  if (!el) return;
  let spans = el.children;
  let top = 0;
  let tmp = '';

  el.innerHTML = el.textContent.replace(/\S+/g, '<n>$&</n>');
  for (let i = 0; i < spans.length; i++) {
    var rect = spans[i].getBoundingClientRect().top;
    if (top < rect) tmp += '</span>' + '<span>';
    top = rect;
    tmp += spans[i].textContent + ' ';
  }
  const array = tmp
    .split('</span>')
    .map(row => row.replace('</span>', '').replace('<span>', ''))
    .filter(Boolean);

  return array;
};
