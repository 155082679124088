import React, { useContext } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Wrapper = styled.footer`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2rem 2.4rem;
  text-transform: uppercase;

  a {
    border-bottom: 1px solid;
  }

  nav a {
    margin: 0 1.6rem;
  }
`;

const Footer = () => {
  return (
    <Wrapper>
      <p>© 2020, Kalok</p>
      <nav>
        <a>Instagram</a>
        <a>Dribbble</a>
        <a>Are.na</a>
      </nav>
      <a>Contact</a>
    </Wrapper>
  );
};

export default Footer;
