import React from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const Wrapper = styled(motion.div)`
  position: fixed;
  pointer-events: none;
  transform: translate(-50%, -50%);
  overflow: hidden;
`;

const ImgWrapper = styled(motion.div)`
  position: relative;
  height: 0;
  width: 49rem;
  padding-bottom: calc(393 / 493 * 100%);

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ProjectImage = React.forwardRef((props, ref) => {
  const [left, top] = props.position;
  return (
    <AnimatePresence key={props.src}>
      {props.visible && (
        <Wrapper
          initial={{
            opacity: 0,
            top,
            left,
            clipPath: `inset(10% 10% 10% 10%)`,
          }}
          animate={{
            opacity: 1,
            top,
            left,
            clipPath: `inset(0% 0% 0% 0%)`,
            transition: {
              top: { duration: 0 },
              left: { duration: 0 },
              clipPath: { ease: 'easeInOut', duration: 0.25 },
              opacity: { ease: 'easeInOut', duration: 0.2 },
            },
          }}
          exit={{
            opacity: 0,
            clipPath: `inset(10% 10% 10% 10%)`,
            transition: {
              clipPath: { ease: 'easeInOut', duration: 0.2 },
              opacity: { duration: 0.15 },
            },
          }}
        >
          <ImgWrapper ref={ref} layoutId="hero">
            <img src={props.src} />
          </ImgWrapper>
        </Wrapper>
      )}
    </AnimatePresence>
  );
});

export default ProjectImage;
