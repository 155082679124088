import React, { useContext, useState, useRef } from 'react';
import { store } from '../store/store';

import { useInView } from 'react-intersection-observer';
import styled, { withTheme } from 'styled-components';
import { motion } from 'framer-motion';
import ProjectItem from './ProjectItem';
import ProjectImage from './ProjectImage';

const ListWrapper = styled.div`
  position: relative;
  margin: 0 15rem 0 6rem;
  min-height: 20rem;
`;

const ListTitle = styled.h3`
  position: absolute;
  transform: rotate(-90deg) translateX(-100%);
  transform-origin: left top;
  font-family: ${({ theme }) => theme.fontFamily};
  padding-right: 5rem;
`;

const List = styled(motion.ul)`
  list-style: none;
  margin: 0;
  padding: 0 0 20rem 9rem;

  &:hover li:not(:hover) {
    opacity: 0.1;
    transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
`;

const Projects = ({ projects }) => {
  const imgRef = React.createRef();
  const [imgVisible, setImgVisible] = useState(false);
  const [imgPosition, setImgPosition] = useState([0, 0]);
  const [imgSource, setImgSource] = useState(null);
  const { state, dispatch } = useContext(store);
  const [ref, inView, entry] = useInView({
    threshold: 0.25,
    triggerOnce: true,
  });

  const list = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.25,
        delayChildren: 1.75,
      },
    },
  };

  const item = {
    hidden: {
      y: 150,
      borderWidth: 0,
    },
    visible: {
      y: 0,
      borderWidth: '100%',
      transition: {
        type: 'spring',
        mass: 1,
        damping: 50,
      },
    },
  };

  const handleMouseMove = e => {
    const { clientX: x, clientY: y } = e;
    setImgPosition([x, y]);
  };

  return (
    <div>
      {state.introIsReady && (
        <ListWrapper ref={ref} onMouseMove={e => handleMouseMove(e)}>
          <ListTitle>Selected projects</ListTitle>
          {inView && (
            <List variants={list} initial="hidden" animate="visible">
              {projects.map((project, i) => (
                <ProjectItem
                  key={project.project.document.id}
                  project={project.project}
                  item={item}
                  setImgSource={setImgSource}
                  setImgVisible={setImgVisible}
                  index={i}
                />
              ))}
            </List>
          )}
        </ListWrapper>
      )}
      <ProjectImage
        ref={imgRef}
        visible={imgVisible}
        position={imgPosition}
        src={imgSource}
      />
    </div>
  );
};

export default withTheme(Projects);
