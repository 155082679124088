import React, { useState, useContext, useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../layouts/Layout';
import IntroTitle from '../components/IntroTitle';
import Header from '../components/Header';
import Projects from '../components/Projects';

import { store } from '../store/store';
import Footer from '../components/Footer';

const FoldWrapper = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
`;

const IndexPage = ({ data }) => {
  const { state, dispatch } = useContext(store);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    dispatch({
      type: 'SET_ACTIVE_PROJECT',
      payload: null,
    });
  }, []);

  const { hero_title, hero_subtitle, projects } = data.prismicHomepage.data;

  return (
    <Layout trigger={trigger}>
      <FoldWrapper>
        <Header />
        <IntroTitle
          title={hero_title}
          subtitle={hero_subtitle}
          dispatch={dispatch}
          setTrigger={setTrigger}
        />
      </FoldWrapper>
      <Projects projects={projects} />
      <Footer />
    </Layout>
  );
};

export default IndexPage;

export const HOMEPAGE_QUERY = graphql`
  query homepage {
    prismicHomepage {
      data {
        hero_title {
          text
        }
        hero_subtitle {
          text
        }
        projects {
          project {
            document {
              ... on PrismicProjects {
                id
                uid
                data {
                  title {
                    text
                  }
                  subtitle {
                    text
                  }
                  color
                  image {
                    localFile {
                      childImageSharp {
                        fixed(height: 393, width: 493, quality: 80) {
                          ...GatsbyImageSharpFixed_withWebp
                        }
                      }
                    }
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
