import React, { useState, useContext, useEffect } from 'react';
import { navigate } from 'gatsby';
import { store } from '../store/store';
import { motion } from 'framer-motion';
import styled, { withTheme } from 'styled-components';

const Wrapper = styled.li`
  position: relative;
  /* border-bottom: 2px solid; */
  /* &:not(:last-child):after {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    width: ${({ shouldAnimate }) => (shouldAnimate ? '100%' : '0px')};
    height: 2px;
    background: black;
    transition: all 1.5s;
  } */
`;

const TitleWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const Title = styled(motion.h2)`
  display: flex;
  font-size: 8rem;
  line-height: 2;
  cursor: pointer;

  span {
    font-size: 1.9rem;
    margin: 3.75rem 0 0 2.4rem;
    font-family: ${({ theme }) => theme.fontFamily};
  }
`;

const ProjectItem = ({
  project,
  item,
  index,
  theme,
  setImgSource,
  setImgVisible,
  handleMouseClick,
}) => {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const { state, dispatch } = useContext(store);
  const { title, subtitle, image, color } = project.document.data;

  return (
    <Wrapper key={title} shouldAnimate={shouldAnimate}>
      <TitleWrapper>
        <Title
          variants={item}
          onHoverStart={() => {
            setImgVisible(true);
            setImgSource(image.url);
            dispatch({
              type: 'SET_BACKGROUND_COLOR',
              payload: color,
            });
          }}
          onHoverEnd={() => {
            setImgVisible(false);
            dispatch({
              type: 'SET_BACKGROUND_COLOR',
              payload: theme.grey,
            });
          }}
          onTap={() => {
            dispatch({
              type: 'SET_ACTIVE_PROJECT',
              payload: image.url,
            });
            setTimeout(() => {
              navigate(`/${project.document.uid}`);
            }, 2000);
          }}
        >
          {title.text}
          <span>{subtitle.text}</span>
        </Title>
        <motion.div
          initial={{ width: 0, height: 2, background: 'black' }}
          animate={{
            width: '100%',
            transition: {
              delay: index * 0.2,
              type: 'spring',
              mass: 1,
              damping: 50,
            },
          }}
        />
      </TitleWrapper>
    </Wrapper>
  );
};

export default withTheme(ProjectItem);
