import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { splitLines } from '../helpers';

const TitleWrapper = styled(motion.div)`
  position: absolute;
  left: 50%;
  top: 50%;
  overflow: ${({ clipped }) => (clipped ? 'hidden' : 'visible')};
`;

const Title = styled(motion.h1)`
  font-family: ${({ theme }) => theme.fontFamilyHeading};
  font-size: 15rem;
  line-height: 0.8;
  color: #fff;
`;

const Text = styled.div`
  font-size: 3.2rem;
  line-height: 0.93;
  text-transform: uppercase;
  max-width: 63rem;
  position: absolute;
  bottom: 10vh;
  left: 6rem;

  p {
    position: relative;
    overflow: hidden;

    span {
      display: block;
    }
  }
`;

const Wrapper = styled(motion.div)``;

const IntroTitle = React.memo(({ dispatch, title, subtitle }) => {
  const textRef = useRef(null);
  const [isClipped, setIsClipped] = useState(true);
  const [isFinished, setIsFinished] = useState(false);
  const scale =
    typeof window !== `undefined` ? window.innerWidth * 0.00215 : null;

  const parent = {
    initial: { x: '-50%', y: '-50%' },
    slideUp: {
      y: '-20vh',
      x: '-50%',
      transition: {
        when: 'afterChildren',
        ease: 'easeInOut',
        duration: 1,
        delay: 0.5,
      },
    },
  };

  const variants = {
    initial: { y: 250 },
    slideUp: {
      y: 0,
      scale: scale,
      color: '#000',
      transition: {
        y: { ease: [1, 0.7, 0.2, 1], duration: 3 },
        scale: { ease: 'easeInOut', delay: 3.5, duration: 0.8 },
        color: { ease: 'easeInOut', delay: 3.5, duration: 0.8 },
      },
    },
  };

  const textVariants = {
    initial: { y: 25 },
    animate: {
      y: 0,
      transition: {
        ease: 'easeInOut',
        staggerChildren: 0.16,
      },
    },
  };

  const item = {
    initial: {
      y: 30,
    },
    animate: {
      y: 0,
      transition: {
        type: 'spring',
        mass: 1,
        damping: 50,
      },
    },
  };

  return (
    <Wrapper>
      <TitleWrapper
        clipped={isClipped ? 1 : 0}
        variants={parent}
        initial="initial"
        animate="slideUp"
        onAnimationStart={() => {
          setTimeout(() => setIsClipped(false), 2000);
          setTimeout(() => {
            setIsFinished(true);
            dispatch({ type: 'SET_INTRO_IS_READY' });
          }, 5600);
        }}
      >
        <Title variants={variants} initial="initial">
          {title.text}
        </Title>
      </TitleWrapper>
      <Text>
        {!isFinished ? (
          <div ref={textRef} style={{ opacity: 0 }}>
            {subtitle.text}
          </div>
        ) : (
          <motion.div
            variants={textVariants}
            initial="initial"
            animate={isFinished && textRef.current && 'animate'}
          >
            {textRef.current &&
              splitLines(textRef.current).map(line => (
                <p key={line}>
                  <motion.span variants={item}>{line}</motion.span>
                </p>
              ))}
          </motion.div>
        )}
      </Text>
    </Wrapper>
  );
});

export default IntroTitle;
